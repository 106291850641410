<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-gradient-bigeron diagonal opacity-8"></span>
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h2 class="display-2 mb-0 text-white">Stránka sa nenašla</h2>
                    <h4 class=" display-5 text-white">Stránka ešte nie je pripravená, alebo sa nenašla</h4>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import {Modal} from 'ant-design-vue'
    import FormItem from 'ant-design-vue'
    import {Cropper} from 'vue-advanced-cropper'

    Vue.use(FormItem, Modal);
    Vue.use(Cropper);




    export default {
        name: 'user',

        data() {
            return {
            }
        },
        methods: {
        },
        mounted() {
        }
    };
</script>
